<template>
  <Page :mode="isLogin == 'undefined' || isLogin == '' ? 'jacket': ''">
     <h1>{{this.pageContent.Title}}</h1>
     <h2 class="display-none-heading">{{this.pageContent.Title}}</h2>
    <div class="copyright-content">
      <p v-html="this.pageContent.Description"></p>
      <p v-html="this.pageContent.Content"></p>
      <div class="back-login text-left">
      <RouteLink v-if="!isLoading && (isLogin == 'undefined' || isLogin == '')" route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import RouteLink from '@/structure/RouteLink.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import axios from 'axios'

export default {
  mixins: [vcm,vvm, customLabels],
  name: 'privacy',
  components: {
    Page, RouteLink
  },
  data() {
    return {
      isLoading: false,
      labels:{
        GoToSignIn: this.get_translation('App','GoToSignIn','label'),
      },
      pageContent: {
        Title: null,
        Description: null,
        Content : null,
      }
    };
  },
  computed: {
    isLogin() {
      return this.$root.get_access_token;
    }
  },
  async beforeCreate () {
    try {
      this.isLoading = true;
      const Url =  process.env.VUE_APP_BASE_URL + '/v1/pages/privacy'
      const response = await axios.get(Url, { params: {language: `${this.$root.config.localization.language}`} })
      if (response.data.responseCode === '200' && response.data.response) {
        this.pageContent.Content = response.data.response.Content
        this.pageContent.Description = response.data.response.Description
        this.pageContent.Title = response.data.response.Title 
         this.isLoading = false; 
      }
    } catch (e) {
      console.log('Error : ' + e)
       this.isLoading = false;
    }
  },
  mounted: function () {    
  }
}

</script>

<style scoped>
.vgs-jacket-content h1 {
    font-size: 30px;
    margin-bottom: 30px;
    color:#primaryColor;
    text-transform: capitalize;
}
  .copyright-content{
    text-align: left;
  }
  .copyright-content h3{
    font-size: 15px;
    font-weight: bold;
    background-color: #ebf0f4;
    padding: 10px 20px;
    color: #0a0a0a;
  }
  .back-login{
  margin-top: 50px;
  }

  .vgs-page p {
    color: #000;
  }

  .display-none-heading {
    display: none;
  }
</style>

